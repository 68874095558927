<div>
  <ul class="nav nav-tabs mb-3">
    <li
      class="nav-item"
      *ngFor="let tab of tabNames | keyvalue"
      (click)="changeTab(tab)"
    >
      <a
        class="nav-link"
        [ngClass]="selectedTab === tab.value ? 'active' : ''"
        >{{ tab.value }}</a
      >
    </li>
  </ul>
</div>

@if(selectedTab === tabNames.tab1){
<gtapp-sites></gtapp-sites>

} @else if(selectedTab === tabNames.tab2){
<div>
  <div
    *ngIf="fileImportInProgress"
    class="alert alert-warning alert-dismissible fade show"
    role="alert"
  >
    <div class="fw-bold">
      File(s) import in progress ({{ fileImportInProgress }})
    </div>
  </div>

  <div
    *ngIf="showArchivedList"
    class="alert alert-primary fw-bold text-center my-3"
    role="alert"
  >
    Showing Deleted Clients
  </div>
  <div class="d-flex flex-column flex-md-row input-btn-group">
    <div class="ms-md-2 mb-2 mb-lg-0">
      <button
        class="btn btn-primary btn-lg w-100"
        (click)="openAddClientForm()"
      >
        + Add Client
      </button>
    </div>
    <div class="ms-md-2 mb-2 mb-lg-0" *ngIf="archivedCount">
      <button
        class="btn btn-secondary btn-lg w-100"
        (click)="getArchivedUnarchivedList()"
      >
        {{ showArchivedList ? "Show Active" : "Show Deleted" }}
      </button>
    </div>
    <div class="flex-md-grow-1 mb-2 mb-md-0 ms-lg-2">
      <gtapp-auto-complete
        [data]="clientDropDownList"
        placeHolder="Search Clients"
        searchKeyword="company_name"
        [itemTemplate]="clientLookupTemplate"
        (inputChanged)="onClientLookup($event)"
        (selected)="openCustomerView($event)"
        (submitted)="
          globalFilter = $event; clientPaginationData = {}; fetchCompanyList()
        "
        [enableSearchCloseOption]="true"
        (fieldCleared)="
          globalFilter = null; clientPaginationData = {}; fetchCompanyList()
        "
      >
      </gtapp-auto-complete>
      <ng-template #clientLookupTemplate let-item>
        <div>{{ item?.company_name }}</div>
      </ng-template>
    </div>

    <div class="ms-md-2 d-none d-lg-block">
      <button
        class="btn btn-secondary btn-lg w-100"
        (click)="openUploadDialogBox()"
      >
        <i class="fa-solid fa-upload"></i> Bulk Upload
      </button>
    </div>
  </div>

  <div class="mt-3">
    <ng-container>
      <gtapp-gt-table
        [desktopTableBackendData]="clientPaginationData[pageNum]"
        [desktopTableDetails]="clientDetailsTableView"
        [tableStyle]="tableStyle"
        [otherParams]="otherParams"
        (sortColumn)="sortColumn($event)"
        (rowClick)="openCustomerView($event)"
        (searchColumn)="searchColumn($event)"
        (paginationChange)="onChangeTablePagination($event)"
      >
      </gtapp-gt-table>
    </ng-container>
  </div>
</div>
}

<!-- @else if (selectedTab === tabNames.tab3) {
<div class="d-flex mb-4">
  <div class="mb-2 flex-grow-1 me-2">
    Key Tree
    <span>
      <span *ngIf="siteKeyTree.client?.id" (click)="siteKeyTree = {}">
        >
        <span class="cursor-pointer text-decoration-underline">
          {{ siteKeyTree.client?.name }}</span
        >
      </span>
      <span *ngIf="siteKeyTree.site?.id" (click)="siteKeyTree.site = {}">
        >
        <span class="cursor-pointer text-decoration-underline">
          {{ siteKeyTree.site?.name }}</span
        >
      </span>
    </span>
  </div>
</div>

<table class="table table-responsive">
  @if(siteKeyTree?.site?.id){
  <ng-container>
    <ul>
      @for (key of siteKeyTree?.site?.keys;track key) {
      <li>
        {{ key }}
      </li>

      }
    </ul>
  </ng-container>
  }@else {
  <ng-container>
    <thead>
      <th>{{ siteKeyTree.client?.id ? "Site" : "Client" }}</th>
      <th>Key Count</th>
    </thead>
    <tbody>
      @for (item of
      siteKeyTree.client?.id?siteKeyTree.client?.site_info:siteKeyListData;
      track item; ) {
      <tr
        [ngClass]="item?.key_count > 0 ? 'cursor-pointer' : ''"
        (click)="
          item?.key_count > 0
            ? (siteKeyTree[siteKeyTree.client?.id ? 'site' : 'client'] = item)
            : null
        "
      >
        <td>
          {{ item?.name }}
        </td>
        <td>{{ item?.key_count }}</td>
      </tr>

      }
    </tbody>
  </ng-container>
  }
</table>
} -->
