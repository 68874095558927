import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  getDistanceUsingHaversine,
  getFormattedDateTime,
  sortArrayOfObject,
} from '../../../../../global.variable';
import { AddEditSiteComponent } from '../../../../shared/components/add-edit-site/add-edit-site.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { DeviceInfoService } from '../../../../shared/services/device-info.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ClientsService } from '../../clients/clients.service';
import { IncidentsService } from '../incidents.service';
type autoDropdownKey = 'clientSelected' | 'siteSelected' | 'incidentSelected';

@Component({
  selector: 'gtapp-add-incident',
  templateUrl: './add-incident.component.html',
  styleUrl: './add-incident.component.scss',
})
export class AddIncidentComponent implements OnInit {
  incidentDict: any = {
    client: {
      name: 'Client',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    site: { name: 'Site', value: '', edit: false, data: null, tempValue: '' },
    incidentType: {
      name: 'Incident Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },

    iTime: {
      name: 'I Time',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    iDay: {
      name: 'I Day',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };

  userData: any;

  dialogRef: any;

  siteList: any = [];
  addIncidentButtonPressed: boolean = false;
  isAdmin = this.dataCheckService.isUserAdmin();
  isDispatchUser = this.dataCheckService.isDispatchUser();

  incidentTypeList: any = [];

  todayDate: any = new Date();
  userLocation: any;

  initialDataList: any = {};

  constructor(
    private incidentService: IncidentsService,
    private dialogService: ModelDialogueService,
    private clientService: ClientsService,
    private router: Router,
    private dataCheckService: DataCheckService,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private deviceInfoService: DeviceInfoService
  ) {
    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.incidentDict.iDay.value = getFormattedDateTime();
    this.getIncidentTypes();
    this.onSiteSearch('');
    this.editField('site');

    this.deviceInfoService
      .getGpsCoordinates()
      .then((gps: any) => (this.userLocation = gps));
  }

  getIncidentTypes(params?: any) {
    this.incidentService.fetchIncidentTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.incidentTypeList = res['data'];
        this.initialDataList.incidentType = JSON.parse(
          JSON.stringify(res?.data)
        );
      }
    });
  }
  onSearchIncidentType(event: any) {
    this.incidentDict.incidentType.tempValue = event.target.value;
    if (event.target.value.length) {
      this.incidentTypeList = this.initialDataList.incidentType?.filter(
        (type: any) =>
          type?.name?.toLowerCase()?.includes(event.target.value?.toLowerCase())
      );
    } else {
      this.incidentTypeList = this.initialDataList.incidentType;
    }
  }
  onSelectIncidentType(event: any) {
    if (event.key === 0) {
      if (this.incidentDict.incidentType.tempValue.length > 0) {
        var requestData = { name: this.incidentDict.incidentType.tempValue };
        this.incidentService
          .createIncidentType(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: '',
                successMessage: response['message'],
              });
              this.incidentDict.incidentType.value = response['data'].name;
              this.incidentDict.incidentType.data = response['data'];
              this.getIncidentTypes();
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      } else {
        this.toasterService.setMessage({
          errorMessage: "Incident Name can't be empty",
          successMessage: '',
        });
      }
    }
    if (event?.id) {
      this.incidentDict.incidentType.value = event?.name;
      this.incidentDict.incidentType.data = event;
    }
  }

  onSiteSearch(event?: any) {
    const searchString = event ? event.target.value : '';
    this.incidentDict.site.tempValue = searchString;

    this.clientService
      .searchSites({
        search_str: searchString,
      })
      .subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.siteList = res['data'];
          if (!event) {
            this.initialDataList.site = JSON.parse(
              JSON.stringify(this.siteList)
            );
          }
          this.sortSiteListData();
        }
      });
  }
  async sortSiteListData() {
    if (!this.userLocation?.lat) {
      this.userLocation = await this.deviceInfoService.getGpsCoordinates();
    }
    this.siteList?.forEach((item: any) => {
      item.hDistance = Number(
        getDistanceUsingHaversine(
          this.userLocation?.lat,
          item?.gps?.lat,
          this.userLocation?.lon,
          item?.gps?.lon
        )
      );
    });
    await Promise.all(
      this.siteList?.map((item: any) => {
        const distance =
          getDistanceUsingHaversine(
            this.userLocation?.lat,
            item?.gps?.lat,
            this.userLocation?.lon,
            item?.gps?.lon
          ) || 0;
        item.hDistance = Number(distance);
      })
    );

    this.siteList = [
      ...sortArrayOfObject(this.siteList, 'hDistance', 'asc'),
    ]?.splice(0, 10);
  }
  onSelectSite(data: any) {
    if (data?.key === 0) {
      this.openAddSiteForm();
    }

    if (data?.id) {
      this.incidentDict.site.value = data?.company_name;
      this.incidentDict.site.data = data;

      if (data?.company) {
        this.incidentDict.client.value = data?.company?.company_name;
        this.incidentDict.client.data = data?.company;
      }

      this.shiftFocus('iTypeLookupField');
    }
  }
  openAddSiteForm() {
    this.dialogRef = this.dialogService.open(AddEditSiteComponent, {
      data: {
        detailData: { company_name: this.incidentDict.site.tempValue },
      },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.onSelectSite(value);
      }
    });
  }
  onAddIncident() {
    this.spinnerService.show();
    this.addIncidentButtonPressed = true;
    let dateTime = new Date(this.incidentDict.iDay.value);

    let incidentData = {
      site_id: this.incidentDict?.site?.data?.id,
      incident_time: dateTime,
      incident_type: this.incidentDict.incidentType.data?.id,
    };
    this.incidentService.addIncident(incidentData).then((response: any) => {
      if (response['status'] == 'success') {
        if (response?.data)
          this.router.navigate([
            '/view-incident',
            { inKey: String(response?.data?.id) },
          ]);
      } else {
        this.addIncidentButtonPressed = false;
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }

      this.spinnerService.hide();
    });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, 200);
  }

  formateStartDate(event: any) {
    if (event.target.value) {
      this.incidentDict.iDay.value = event.target.value;
    } else {
      this.incidentDict.iDay.value = getFormattedDateTime();
    }
  }
  editField(field: string) {
    if (field == 'site') {
      this.incidentDict.site = {
        name: 'Site',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.incidentDict.client = {
        name: 'Client',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };
      this.shiftFocus('siteId');
    }

    if (field == 'it') {
      this.incidentDict.incidentType = {
        name: 'Incident Type',
        value: '',
        edit: false,
        data: null,
        tempValue: '',
      };

      this.shiftFocus('iTypeLookupField');
    }

    this.updateInitialDataList(field);
  }
  updateInitialDataList(field: any) {
    setTimeout(() => {
      if (field === 'site') {
        this.siteList = JSON.parse(
          JSON.stringify(this.initialDataList?.[field])
        );
        this.sortSiteListData();
      } else if (field === 'it') {
        this.incidentTypeList = JSON.parse(
          JSON.stringify(this.initialDataList?.incidentType)
        );
      }
    }, 200);
  }
}
